import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { ThemeUIStyleObject } from 'theme-ui';

export const VIEW_CUSTOMER_FLOW_BUTTON_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  position: 'absolute',
  right: '24px',
  bottom: '24px',
  backgroundColor: '#0E61F6',
  color: 'textSecondary',
  fontWeight: '600',
  fontSize: '13px',
  lineHeight: '16px',
  borderRadius: 3,
  padding: [null, null, '10px 20px'],
};

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  height: '100%',
  width: '100%',
  borderRadius: 2,
  border: '4px solid',
  borderColor: 'primary',
  position: 'relative',
};
