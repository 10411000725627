import React from 'react';
import { Box } from 'theme-ui';

import { HighlightedText } from '@sprinklr/shared-lib/components/higlightedText';
import UseCaseLabel from '../UseCaseLabel';
import WorkflowAccordian from '../WorkflowAccordian';
import WorkflowPreview from '../WorkflowPreview';

import {
  MODAL_CONTAINER_STYLES,
  LEFT_COLUMN_CONTAINER_STYLES,
  HEADLINE_BOX_STYLES,
  HEADLINE_TEXT_STYLES,
  ACCORDIAN_CONTAINER_STYLES,
  RIGHT_COLUMN_CONTAINER_STYLES,
} from './styles';

import { Props } from '../../types';

const Showcase = ({
  headline,
  workflows,
  activeWorkflow,
  setActiveWorkflow,
  setShowWorkflow,
}: Props & {
  activeWorkflow: number;
  setActiveWorkflow: (id: number) => void;
  setShowWorkflow: (showWorkflow: boolean) => void;
}) => {
  return (
    <Box sx={MODAL_CONTAINER_STYLES}>
      <Box sx={LEFT_COLUMN_CONTAINER_STYLES}>
        <UseCaseLabel />
        <HighlightedText
          boxSx={HEADLINE_BOX_STYLES}
          textSx={HEADLINE_TEXT_STYLES}
        >
          {headline}
        </HighlightedText>
        <Box sx={ACCORDIAN_CONTAINER_STYLES}>
          <WorkflowAccordian
            workflows={workflows}
            activeWorkflow={activeWorkflow}
            setActiveWorkflow={setActiveWorkflow}
          />
        </Box>
      </Box>
      <Box sx={RIGHT_COLUMN_CONTAINER_STYLES}>
        <WorkflowPreview
          workflows={workflows}
          activeWorkflow={activeWorkflow}
          setShowWorkflow={setShowWorkflow}
        />
      </Box>
    </Box>
  );
};

export default Showcase;
