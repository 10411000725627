/** @jsx jsx */
import { ChatComponent, FlowChart } from '@sprinklr/shared-lib';
import { useCallback, useState } from 'react';
import { Box, Flex, Text, jsx } from 'theme-ui';

import { Workflow } from './types';
import UseCaseLabel from '../workflowShowcaseTemplate/components/UseCaseLabel';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import {
  CONTAINER_STYLES,
  CHAT_CONTAINER_STYLES,
  HEADER_CONTAINER_STYLES,
  BACK_NAV_BUTTON_STYLES,
  BACK_ARROW_ICON_STYLES,
  BREADCRUMB_CONTAINER_STYLES,
  BREADCRUMB_ARROW_ICON_STYLES,
  TITLE_TEXT_STYLES,
  FLOW_CHART_CONTAINER_STYLES,
  GRADIENT_INSET,
} from './styles';

const WorkFlowTemplate = ({
  title,
  flowChart,
  chatPreview,
  events,
  setShowWorkflow,
}: Workflow & {
  setShowWorkflow: (showWorkflow: boolean) => void;
}) => {
  const [activeHighlightId, setActiveHighlightId] = useState<string | null>(
    null,
  );

  const handleEvent = useCallback(
    (highlightId: string | null) => {
      const event = events.find(event => event.node.id === highlightId);
      setActiveHighlightId(event ? event.chat.id : null);
    },
    [setActiveHighlightId],
  );

  const handleBackButtonClick = useCallback(
    () => setShowWorkflow(false),
    [setShowWorkflow],
  );
  return (
    <Flex sx={CONTAINER_STYLES}>
      <Flex sx={CHAT_CONTAINER_STYLES}>
        <Box sx={HEADER_CONTAINER_STYLES}>
          <button sx={BACK_NAV_BUTTON_STYLES} onClick={handleBackButtonClick}>
            <Icon iconName="arrow" iconSx={BACK_ARROW_ICON_STYLES} />
          </button>
          <Text sx={BREADCRUMB_CONTAINER_STYLES}>
            <UseCaseLabel />
            <Icon
              iconName="dropDownArrow"
              iconSx={BREADCRUMB_ARROW_ICON_STYLES}
            />
            <Text sx={TITLE_TEXT_STYLES}>{title}</Text>
          </Text>
        </Box>
        <ChatComponent {...chatPreview} activeHighlightId={activeHighlightId} />
      </Flex>
      <Box className="data-x" sx={FLOW_CHART_CONTAINER_STYLES}>
        <Box sx={GRADIENT_INSET} />
        <FlowChart
          nodes={flowChart.nodes}
          edges={flowChart.edges}
          onHighlightEvent={handleEvent}
        />
      </Box>
    </Flex>
  );
};

export default WorkFlowTemplate;
