import React, { useState } from 'react';
import { Box } from 'theme-ui';

import WorkFlowTemplate from '../workflowTemplate';
import Showcase from './components/Showcase';

import { Props } from './types';

import { WORKFLOW_CONTAINER } from './styles';

const WorkflowShowcaseTemplate = (props: Props) => {
  const [activeWorkflow, setActiveWorkflow] = useState(0);
  const [showWorkflow, setShowWorkflow] = useState(false);

  return showWorkflow ? (
    <Box sx={WORKFLOW_CONTAINER}>
      <WorkFlowTemplate
        {...props.workflows[activeWorkflow]}
        setShowWorkflow={setShowWorkflow}
      />
    </Box>
  ) : (
    <Showcase
      {...props}
      activeWorkflow={activeWorkflow}
      setActiveWorkflow={setActiveWorkflow}
      setShowWorkflow={setShowWorkflow}
    />
  );
};

export default WorkflowShowcaseTemplate;
