/** @jsx jsx */
import { useCallback, useMemo } from 'react';
import { Box, ThemeUIStyleObject, jsx } from 'theme-ui';
import { FlowChart } from '@sprinklr/shared-lib';
import { Workflow } from '../../../workflowTemplate/types';
import { VIEW_CUSTOMER_FLOW_LABEL } from './constants';
import { CONTAINER_STYLES, VIEW_CUSTOMER_FLOW_BUTTON_STYLES } from './styles';

function getWorkflowPreviewItemStyles(isActive: boolean): ThemeUIStyleObject {
  return {
    opacity: isActive ? 1 : 0,
    pointerEvents: isActive ? 'initial' : 'none',
    transition: 'opacity 200ms',
    position: 'absolute',
    width: '100%',
    height: '100%',
  };
}

function WorkflowPreviewItem({
  workflow,
  isActive,
}: {
  workflow: Workflow;
  isActive: boolean;
}) {
  const styles = useMemo<ThemeUIStyleObject>(
    () => getWorkflowPreviewItemStyles(isActive),
    [isActive],
  );

  return (
    <Box sx={styles}>
      <FlowChart
        nodes={workflow.flowChart.nodes}
        edges={workflow.flowChart.edges}
        mode={'PREVIEW'}
      />
    </Box>
  );
}

const WorkflowPreview = ({
  workflows,
  activeWorkflow,
  setShowWorkflow,
}: {
  workflows: Workflow[];
  activeWorkflow: number;
  setShowWorkflow: (showWorkflow: boolean) => void;
}) => {
  const handleViewCustomerFlowClick = useCallback(() => {
    setShowWorkflow(true);
  }, [setShowWorkflow]);
  return (
    <Box sx={CONTAINER_STYLES}>
      {workflows.map((workflow, index) => {
        return (
          <WorkflowPreviewItem
            workflow={workflow}
            isActive={index === activeWorkflow}
          />
        );
      })}
      <button
        sx={VIEW_CUSTOMER_FLOW_BUTTON_STYLES}
        onClick={handleViewCustomerFlowClick}
      >
        {VIEW_CUSTOMER_FLOW_LABEL}
      </button>
    </Box>
  );
};

export default WorkflowPreview;
