/** @jsx jsx */
import { useCallback, useMemo } from 'react';
import { Box, Text, jsx } from 'theme-ui';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { RichText } from '@sprinklr/shared-lib';
import { useMeasure } from 'react-use';
import { Props } from './types';
import { getStyles } from './AccordianItem.styles';

const AccordianItem = ({
  id,
  icon,
  title,
  description,
  isActive,
  onClick,
}: Props) => {
  const handleClick = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  const [accordionItemContentRef, { height: contentHeight }] = useMeasure();

  const styles = useMemo(
    () => getStyles(isActive, contentHeight),
    [isActive, contentHeight],
  );

  return (
    <button sx={styles.container} onClick={handleClick}>
      <Box sx={styles.headerContainer}>
        <Box sx={styles.titleContainer}>
          <Icon iconName={icon.icon} iconSx={styles.icon} />
          <Text sx={styles.title}>{title}</Text>
        </Box>
        <Icon iconName="chevron" iconSx={styles.dropdownArrow} />
      </Box>
      <Box sx={styles.descriptionOuterContainer}>
        <Box ref={accordionItemContentRef}>
          <Box sx={styles.descriptionInnerContainer}>
            <RichText richTextObject={description} />
          </Box>
        </Box>
      </Box>
    </button>
  );
};

export default AccordianItem;
