import React from 'react';
import { Box } from 'theme-ui';

import { Workflow } from '../../../workflowTemplate/types';
import { CONTAINER_STYLES } from './styles';
import AccordianItem from './AccordianItem';

const WorkflowAccordian = ({
  workflows,
  activeWorkflow,
  setActiveWorkflow,
}: {
  workflows: Workflow[];
  activeWorkflow: number;
  setActiveWorkflow: (id: number) => void;
}) => {
  const handleClick = (selectedId: string) => {
    const activeIndex = workflows.findIndex(
      workflow => workflow.id === selectedId,
    );
    setActiveWorkflow(activeIndex);
  };
  return (
    <Box sx={CONTAINER_STYLES}>
      {workflows.map((workflow, currentIndex) => {
        return (
          <AccordianItem
            key={workflow.id}
            id={workflow.id}
            icon={workflow.icon}
            title={workflow.title}
            description={workflow.description}
            isActive={activeWorkflow === currentIndex}
            onClick={handleClick}
          />
        );
      })}
    </Box>
  );
};

export default WorkflowAccordian;
