import { ThemeUICSSObject } from 'theme-ui';
import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';

export const CONTAINER_STYLES: ThemeUICSSObject = {
  width: '100%',
  height: '100%',
  gap: '72px',
  overflow: 'hidden',
};
export const CHAT_CONTAINER_STYLES: ThemeUICSSObject = {
  flex: 'none',
  maxWidth: '45%',
  flexDirection: 'column',
  gap: '40px',
  alignItems: 'flex-start',
  paddingTop: '60px',
  paddingLeft: '80px',
};
export const FLOW_CHART_CONTAINER_STYLES: ThemeUICSSObject = {
  flex: 1,
  position: 'relative',
};
export const TITLE_TEXT_STYLES = {
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '28px',
};
export const BREADCRUMB_ARROW_ICON_STYLES: ThemeUICSSObject = {
  transform: 'rotate(-90deg)',
  width: '8px',
  height: '8px',
  flex: 'none',
};
export const BREADCRUMB_CONTAINER_STYLES: ThemeUICSSObject = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flexWrap: 'wrap',
};
export const BACK_ARROW_ICON_STYLES: ThemeUICSSObject = {
  fill: '#818192',
  transform: 'rotate(180deg)',
  width: '24px',
  height: '24px',
  display: 'block',
};
export const BACK_NAV_BUTTON_STYLES: ThemeUICSSObject = {
  ...buttonReset,
  cursor: 'pointer',
  height: '24px',
  marginTop: '2px',
};
export const HEADER_CONTAINER_STYLES: ThemeUICSSObject = {
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  justifyContent: 'flex-start',
};
export const GRADIENT_INSET: ThemeUICSSObject = {
  position: 'absolute',
  height: '100%',
  width: '80px',
  background:
    'linear-gradient(270deg, rgba(251, 251, 251, 0) 50.59%, #FBFBFB 100%)',
  zIndex: 1,
};
