import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { ThemeUIStyleObject } from 'theme-ui';

const BASE_TITLE_STYLES: ThemeUIStyleObject = {
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.3px',
  textTransform: 'capitalize',
};

const BASE_CONTAINER_STYLES: ThemeUIStyleObject = {
  ...buttonReset,
  boxShadow: '0px 1px 4px rgba(10, 10, 20, 0.15)',
  borderRadius: 1,
  padding: '16px 20px',
  textAlign: 'left',
  transition: 'border 200ms ease-in',
};

const BASE_DESCRIPTION_OUTER_CONTAINER_STYLES: ThemeUIStyleObject = {
  transition: 'height 200ms',
  overflow: 'hidden',
  marginLeft: '52px',
};

const DESCRIPTION_INNER_CONTAINER_STYLES: ThemeUIStyleObject = {
  paddingTop: 2,
  p: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
  },
};
const ICON_STYLES: ThemeUIStyleObject = {
  width: '36px',
  flex: '1 0 auto',
  height: '36px',
};

const HEADER_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const BASE_DROPDOWN_ARROW_STYLES: ThemeUIStyleObject = {
  width: '12px',
  height: '12px',
  fill: 'primary',
};

const TITLE_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
};

export const getStyles = (
  isActive: boolean,
  contentHeight: number,
): {
  [key: string]: ThemeUIStyleObject;
} => {
  return {
    container: {
      ...BASE_CONTAINER_STYLES,
      border: isActive
        ? '4px solid rgba(16, 126, 255, 0.2)'
        : '4px solid transparent',
    },
    headerContainer: HEADER_CONTAINER_STYLES,
    titleContainer: TITLE_CONTAINER_STYLES,
    icon: ICON_STYLES,
    title: {
      ...BASE_TITLE_STYLES,
      fontWeight: isActive ? '700' : '500',
    },
    dropdownArrow: {
      ...BASE_DROPDOWN_ARROW_STYLES,
      transform: isActive ? 'rotate(180deg)' : 'rotate(-90deg)',
    },
    descriptionOuterContainer: {
      ...BASE_DESCRIPTION_OUTER_CONTAINER_STYLES,
      height: `${isActive ? contentHeight : 0}px`,
    },
    descriptionInnerContainer: DESCRIPTION_INNER_CONTAINER_STYLES,
  };
};
