import React from 'react';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'theme-ui';
import { CONTAINER_STYLES, ICON_STYLES, TEXT_STYLES } from './styles';

const LABEL_TEXT = 'Use Cases';

const UseCaseLabel = () => {
  const { t } = useTranslation();
  return (
    <Box sx={CONTAINER_STYLES}>
      <Icon iconName="influencer" iconSx={ICON_STYLES} />
      <Text sx={TEXT_STYLES}>{t(LABEL_TEXT)}</Text>
    </Box>
  );
};

export default UseCaseLabel;
