import { ThemeUIStyleObject } from 'theme-ui';

export const CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
export const ICON_STYLES: ThemeUIStyleObject = {
  fill: '#818192',
  width: '24px',
  height: '24px',
};
export const TEXT_STYLES: ThemeUIStyleObject = {
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '28px',
  color: '#818192',
  marginLeft: '8px',
};
