import { ThemeUIStyleObject } from 'theme-ui';

export const MODAL_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  gap: '76px',
  backgroundColor: '#fff',
  borderRadius: 2,
  height: '80vh',
  padding: '60px 80px',
};
export const LEFT_COLUMN_CONTAINER_STYLES: ThemeUIStyleObject = {
  flex: 1,
};
export const HEADLINE_BOX_STYLES: ThemeUIStyleObject = { marginTop: 2 };
export const HEADLINE_TEXT_STYLES: ThemeUIStyleObject = {
  fontWeight: '700',
  fontSize: '36px',
  lineHeight: '44px',
};
export const ACCORDIAN_CONTAINER_STYLES: ThemeUIStyleObject = {
  mt: '28px',
};
export const RIGHT_COLUMN_CONTAINER_STYLES: ThemeUIStyleObject = {
  flex: 1,
};
